<template>

    <div class="bigBox">
        <div class="wdxmTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toLink('wdxm')">
            <p class="wdxmTopBoxTitle">我的项目</p>
        </div>
        <div style="height:.88rem"></div>



        <div style="height:.1rem;background: #F6F6F6;"></div>

        <div class="xmxqBigBox">

            <div class="xmxqBox1">

                <div class="movieimg"  v-if="movieDetail">
                    <img :src="movieDetail.squarePic">
                </div>
                <div class="movietxtbox"  v-if="movieDetail">
                    <p class="movietitle">{{movieDetail.movie.slice(1,-1)}}</p>
                    <p class="movietxt">购买金额：¥{{movieDetail.orderAmount}}</p>
                    <p class="movietxt">购买时间：{{movieDetail.delistedTime}}</p>
                    <!-- <p class="movietxtcompanyName">项目方：{{movieDetail.companyName}}</p> -->

                </div>

            </div>


            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组223_1686391601118.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">提交《受让申请书》</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #34C724;marginRight:.2rem;">已完成</span>
                            2023年06月07日
                        </p>
                    </div>
                    <p class="xmlcBoxTopBoxP3">下载申请书</p>
                </div>
            </div>

            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">

            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组223_1686391601118.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">保证金缴纳（成交金额3%）</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #34C724;marginRight:.2rem;">已完成</span>
                            2023年06月07日
                        </p>
                    </div>
                    <!-- <p class="xmlcBoxTopBoxP3">提交</p> -->
                </div>
                <div class="xmlcBoxXian"></div>



                <el-image 
                    v-if="imageUrlUploader"
                    style="width: 100%;border-radius: .2rem;object-fit: cover;"
                    :src="imageUrl" 
                    :preview-src-list="imageUrlList">
                </el-image>

                <el-upload
                    class="my-uploader"
                    action="http://192.168.10.192:10522/jeecg-boot/sys/common/upload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    v-else
                >
                    <img v-if="imageUrl" :src="imageUrl" class="uploaderImg" />
                    
                    <i v-if="!imageUrl" class="el-icon-plus avatar-uploader-icon" style="fontSize:.5rem;font-weight: bold;"></i>
                    <div v-if="!imageUrl" class="el-upload__text" style="fontSize:.24rem;">上传转账凭证</div>
                </el-upload>

                


                <p class="xmlcBoxTopBoxP4">应缴金额：8,989,899.00元</p>

                <p class="xmlcBoxTopBoxP5">
                    开户名：xxxxxxxxxxxxxx
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('xxxxxxxxxxxxxx')">复制</span>
                </p>
                <p class="xmlcBoxTopBoxP5">
                    开户行：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')">复制</span>
                </p>
                <p class="xmlcBoxTopBoxP5">
                    账&nbsp;&nbsp;&nbsp;号：23123434344345346576896
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('23123434344345346576896')">复制</span>
                </p>

            </div>

            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">



            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组706_1686396706877.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">双方信息审核确认</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #DBA871;;marginRight:.2rem;">进行中</span>
                        </p>
                    </div>
                    <p class="xmlcBoxTopBoxP3">提交</p>
                </div>
            </div>


            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">




            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组721_1686396852162.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">支付尾款（至出品公司）</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #A30001;marginRight:.2rem;">已取消</span>
                        </p>
                    </div>
                    <p class="xmlcBoxTopBoxP3" @click="uploadImg">提交</p>
                </div>
                <div class="xmlcBoxXian"></div>


                <el-upload
                    class="my-uploader"
                    action="http://192.168.10.192:10522/jeecg-boot/sys/common/upload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                >
                    <img v-if="imageUrl2" :src="imageUrl2" class="uploaderImg" />
                    <i v-if="!imageUrl2" class="el-icon-plus avatar-uploader-icon" style="fontSize:.5rem;font-weight: bold;"></i>
                    <div v-if="!imageUrl2" class="el-upload__text" style="fontSize:.24rem;">上传转账凭证</div>
                </el-upload>


                <p class="xmlcBoxTopBoxP4">应缴金额：8,989,899.00元</p>

                <p class="xmlcBoxTopBoxP5">
                    开户名：xxxxxxxxxxxxxx
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('xxxxxxxxxxxxxx')">复制</span>
                </p>
                <p class="xmlcBoxTopBoxP5">
                    开户行：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')">复制</span>
                </p>
                <p class="xmlcBoxTopBoxP5">
                    账&nbsp;&nbsp;&nbsp;号：23123434344345346576896
                    <span class="xmlcBoxTopBoxP5Btn" @click="copy('23123434344345346576896')">复制</span>
                </p>

            </div>

            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">



            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组714_1686396939875.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">签署《产权交易合同》</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #999999;;marginRight:.2rem;">未开始</span>
                        </p>
                    </div>
                    <!-- <p class="xmlcBoxTopBoxP3">提交</p> -->
                </div>
            </div>


            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">


            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组714_1686396939875.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">交易所扣除手续费</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #999999;;marginRight:.2rem;">未开始</span>
                        </p>
                    </div>
                    <!-- <p class="xmlcBoxTopBoxP3">提交</p> -->
                </div>
            </div>

            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组196_1686393210189.png" class="nextJt">



            <div class="xmlcBox">
                <div class="xmlcBoxTopBox">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组714_1686396939875.png" class="xmlcImg">
                    <div class="xmlcBoxTopBox1">
                        <p class="xmlcBoxTopBoxP1">交易所备案</p>
                        <p class="xmlcBoxTopBoxP2">
                            <span style="color: #999999;;marginRight:.2rem;">未开始</span>
                        </p>
                    </div>
                    <!-- <p class="xmlcBoxTopBoxP3">提交</p> -->
                </div>
            </div>


            



        </div>
        



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister,getDelistedOrderDetail} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            headers:{},
            imageUrl:'https://zhongyinwh.oss-cn-beijing.aliyuncs.com/temp/Image(2)_1685166746205.png',
            imageUrlList:['https://zhongyinwh.oss-cn-beijing.aliyuncs.com/temp/Image(2)_1685166746205.png'],
            imageUrlUploader:true,
            imageUrl2:null,
            imageUrlList2:[],
            imageUrlUploader2:false,
            load : null,
            wjsToken: null,
            wjsUserInfo: null,
            movieDetail:null,
        }
    },
    methods:{


        uploadImg(){
            console.log(this.imageUrl2)
        },

        copy(value){
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value',value);
            input.setAttribute('create',1);
            input.select();
            document.execCommand("Copy");
            var list=document.getElementsByTagName('input')
            var inputList = Array.prototype.slice.call(list)
            inputList.forEach((item)=>{
                if(item.getAttribute('create'))document.body.removeChild(item);
            });
            Message.success({
                message: '已复制',
                center: true,
                duration:2000,
                showClose: false,
            })
        },

        // 图片上传成功的操作
	    handleAvatarSuccess(res, file) {
            if (res.msgCode === 200) {
                console.log('res')
                //this.imageUrl = URL.createObjectURL(file.raw)
            } else {
                this.$message.error(res.msgContent)
            }
        },
        // 图片上传前的判断
        beforeAvatarUpload(file) {
        	let imgType = ['jpg','jpeg','png']
	        let judge = false // 后缀
	        let type = file.name.split('.')[file.name.split('.').length - 1]
	        for (let k = 0; k < imgType.length; k++) {
	          if (imgType[k].toUpperCase() === type.toUpperCase()) {
	            judge = true
	            break
	          }
	        }
	        // 验证图片格式
	        if (!judge) {
                Message.error({
                    message: '图片格式只支持：JPG、JPEG、PNG',
                    center: true,
                    duration:2000,
                    showClose: false,
                })
                return false
	        }
            //const isLt1M = file.size / 1024 / 1024
            // if (isLt1M > 1) {
            //     this.$message.error('上传头像图片大小不能超过1MB')
            //     return false
            // }
            return true
        },

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        toBack(){
            this.$router.back(-1)
        },

        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },

            

    },
    mounted(){

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)
        console.log(this.$route.params.xmId)

        if(!this.$route.params.xmId){
            this.$router.back(-1)
        }

        if(this.wjsToken&&this.wjsUserInfo){
            this.showLoading()
            getDelistedOrderDetail({
                "data": {
                    "orderId": this.$route.params.xmId
                }
            }).then(res => {
                console.log(res)
                if(res.code == 200){
                    this.movieDetail = res.result

                }else{
                    Message.error({
                        message: res.message,
                        center: true,
                        duration:2000,
                        showClose: false,
                    })
                }
            })



            this.load && this.load.close();

        }else{

            Message.error({
                message: '请登录',
                center: true,
                duration:2000,
                showClose: false,
            })
            this.toLink('dl');


        }

        

    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .wdxmTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .wdxmTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }

    .xmxqBigBox{
        padding: .28rem .22rem;
        box-sizing: border-box;
        width: 100%;
    }
    .xmxqBox1{
        width: 100%;
        height: 2.7rem;
        display: flex;
        margin-bottom: 0.3rem;
        .movieimg{
            height: 2.7rem;
            width: 2.7rem;
            margin-right: 0.2rem;
            border-radius: 0.12rem;
        }
        .movieimg img{
            height: 2.7rem;
            width: 2.7rem;
            object-fit: cover;
        }
        .movietxtbox{
            width: calc(100% - 2.9rem);
            height: 100%;
        }
        .movietxtbox .movietitle{
            font-size: 0.42rem;
            font-weight: bold;
            line-height: 0.54rem;
            color: #252525;
            margin-bottom: 0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .movietxtbox .movietxt{
            font-size: 0.24rem;
            font-weight: 400;
            line-height: 0.34rem;
            color: #BABABA;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0.1rem;
        }
        .movietxtbox .movietxt2{
            font-size: 0.24rem;
            font-weight: 400;
            line-height: 0.34rem;
            color: #BABABA;
            display: flex;
            margin-bottom: 0.1rem;
        }
        .movietxtbox .movietxt2 div{
            white-space: nowrap;
        }
        .movietxtbox .movietxt2 p{
            display: -webkit-box;    
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 2;    
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .movietxtcompanyName{
            font-size: 0.24rem;
            font-weight: 400;
            line-height: 0.34rem;
            color: #BABABA;
            margin-bottom: 0.1rem;
            display: -webkit-box;    
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: justify;
        }

    }
    
    .xmlcBox{
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.05);
        border-radius: .08rem;
        padding: .34rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin-bottom: .16rem;
        .xmlcBoxTopBox{
            width: 100%;
            display: flex;
            position: relative;
            .xmlcImg{
                width: .38rem;
                height: .38rem;
                margin-right: .28rem;

            }
            .xmlcBoxTopBox1{
                .xmlcBoxTopBoxP1{
                    font-size: .32rem;
                    color: #343434;
                    white-space: nowrap;
                    margin-bottom: .12rem;
                }
                .xmlcBoxTopBoxP2{
                    font-size: .24rem;
                    color: #999999;
                }
            }
            .xmlcBoxTopBoxP3{
                font-size: .26rem;
                color: #002BFF;
                position: absolute;
                right: 0;
                line-height: .86rem;
            }
        }
        .xmlcBoxXian{
            background-color: rgba(165, 165, 165, .2);
            width: 100%;
            height: .02rem;
            margin-top: .18rem;
            margin-bottom: .3rem;
        }
        .uploaderImg{
            width: 100%;
            border-radius: .2rem;
            object-fit: cover;
        }

        .xmlcBoxTopBoxP4{
            font-size: .32rem;
            color: #343434;
            margin-top: .3rem;
            margin-bottom: .2rem;
        }
        .xmlcBoxTopBoxP5{
            position: relative;
            font-size: .26rem;
            color: #999999;
            margin-bottom: .14rem;
            white-space: nowrap;

            .xmlcBoxTopBoxP5Btn{
                position: absolute;
                right: 0;
                font-size: .26rem;
                color: #002BFF;
            }
        }
        .xmlcBoxTopBoxP5:last-child{
            margin-bottom: 0;
        }
        
    }

    .nextJt{
        width: .38rem;
        height: .26rem;
        object-fit: cover;
        margin: 0 auto .16rem;
    }








}

.noData{
    font-size: .4rem;
    text-align: center;
    line-height: 3rem;
}


</style>